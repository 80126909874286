import React, { FC } from 'react';
import styled from 'styled-components';

import { Link } from '~src/components/link';
import Layout from '~src/layouts/page-layout';

const Wrapper = styled.div`
  color: #000;
  background-color: ${p => p.theme.colors.red};
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  ${p => p.theme.maxWidth.tablet`
    justify-content: center;
  `};
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 50em;
  height: 20em;
  ${p => p.theme.maxWidth.tablet`
    font-size: 0.8em;
  `};
  ${p => p.theme.maxWidth.phone`
    font-size: 0.6em;
  `};
  ${p => p.theme.maxWidth.small`
    font-size: 0.5em;
  `};
`;

const H1 = styled.h1`
  display: inline-block;
  font-size: 14.75em;
  font-weight: 600;
  color: transparent;
  -webkit-text-stroke: 1px ${p => p.theme.colors.dark};
  margin-bottom: 0;
`;

const H2 = styled.h2`
  font-size: 3em;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 1.4;
  text-align: left;
  width: 4.6em;
  height: 4em;
  margin: 0;
`;

const Separator = styled.div`
  width: 1px;
  height: 13.25em;
  background-color: ${p => p.theme.colors.red};
`;

const BackLink = styled(Link)`
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 1.3;
  text-transform: uppercase;
  color: ${p => p.theme.colors.dark};
  padding: 0.75em 2em;
  border: solid 0.5px ${p => p.theme.colors.dark};
  text-align: center;
  width: fit-content;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${p => p.theme.colors.white};
    background-color: ${p => p.theme.colors.dark};
  }

  ${p => p.theme.maxWidth.tablet`
    font-size: 1.3em;
  `}
  ${p => p.theme.maxWidth.phone`
    margin-top: 1em;
  `}
`;

const seoData = {
  title: '404: Not found',
};

const NotFoundPage: FC = () => (
  <Layout seoData={seoData}>
    <Wrapper>
      <Content>
        <Main>
          <H1>404</H1>
          <Separator />
          <H2>Page not found</H2>
        </Main>
        <BackLink to="/">Back to start</BackLink>
      </Content>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
